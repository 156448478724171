<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <b-card>
        <div
          class="d-flex justify-content-between align-items-center text-center"
        >
          <div class="d-flex">
            <span class="pr-1 padding">
              <h2>ORDER ID</h2>
            </span>
            <span class="bg-primary px-2 padding rounded-lg">
              <h2 class="text-light">{{ order.id }}</h2>
            </span>
          </div>
          <div class="d-flex">
            <span class="pr-1 padding">
              <h2>TOTAL</h2>
            </span>
            <span class="bg-success px-2 padding rounded-lg">
              <h2 class="text-light">
                {{
                  order.quote
                    ? $helpers.formatTotal(order.quote.total)
                    : "RM 0.00"
                }}
              </h2>
            </span>
          </div>
        </div>

        <!-- Date Details -->
        <div class="d-flex justify-content-center align-items-center my-1">
          <hr class="flex-grow-1" />
          <span class="mx-2 font-weight-bold">Date Details</span>
          <hr class="flex-grow-1" />
        </div>

        <!-- PICKUP AND DELIVERY DATE -->
        <b-row class="d-flex justify-content-center">
          <b-col md="6">
            <order-data
              title="Pickup Date"
              :data="$helpers.formatDate(order.pickup_date)"
            />
          </b-col>
          <b-col md="6">
            <order-data
              title="Delivery Date"
              :data="order.quote ? order.quote.delivery_date_format : '-'"
            />
          </b-col>
        </b-row>

        <!--SENDER AND RECIPIENT DETAILS -->
        <div class="d-flex justify-content-center align-items-center mb-2">
          <hr class="flex-grow-1" />
          <span class="mx-2 font-weight-bold">
            Sender & Recipient Details
          </span>
          <hr class="flex-grow-1" />
        </div>
        <!-- NAME -->
        <b-row class="d-flex justify-content-center">
          <b-col md="6">
            <order-data
              title="Sender Name (Company Name)"
              :data="order.sender ? order.sender.name : '-'"
            />
          </b-col>
          <b-col md="6">
            <order-data
              title="Recipient Name (Company Name)"
              :data="order.recipient ? order.recipient.name : '-'"
            />
          </b-col>
        </b-row>

        <div v-if="order.shipment_delivery_type === 'Door to Door'">
          <!-- UNIT NUMBER & ADDRESS 1 -->
          <b-row class="d-flex justify-content-center">
            <!-- ORIGIN -->
            <b-col md="6">
              <b-row class="d-flex justify-content-center">
                <b-col md="4">
                  <order-data title="Unit Number" :data="order.sender.unit" />
                </b-col>
                <b-col md="8">
                  <order-data title="Address 1" :data="order.sender.address1" />
                </b-col>
              </b-row>
            </b-col>

            <!-- DROP OFF -->
            <b-col md="6">
              <b-row class="d-flex justify-content-center">
                <b-col md="4">
                  <order-data
                    title="Unit Number"
                    :data="order.recipient.unit"
                  />
                </b-col>
                <b-col md="8">
                  <order-data
                    title="Address 1"
                    :data="order.recipient.address1"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- ADDRESS 2 & POSTAL CODE -->
          <b-row class="d-flex justify-content-center">
            <!-- ORIGIN -->
            <b-col md="6">
              <b-row class="d-flex justify-content-center">
                <b-col md="8">
                  <order-data title="Address 2" :data="order.sender.address1" />
                </b-col>
                <b-col md="4">
                  <order-data
                    title="Postal Code"
                    :data="order.sender.postcode"
                  />
                </b-col>
              </b-row>
            </b-col>

            <!-- DROP OFF -->
            <b-col md="6">
              <b-row class="d-flex justify-content-center">
                <b-col md="4">
                  <order-data
                    title="Address 2"
                    :data="order.recipient.address2"
                  />
                </b-col>
                <b-col md="8">
                  <order-data
                    title="Postal Code"
                    :data="order.recipient.postcode"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- STATE & COUNTRY -->
          <b-row class="d-flex justify-content-center">
            <!-- ORIGIN -->
            <b-col md="6">
              <b-row class="d-flex justify-content-center">
                <b-col md="6">
                  <order-data title="State" :data="order.sender.state" />
                </b-col>
                <b-col md="6">
                  <order-data title="Country" :data="order.sender.country" />
                </b-col>
              </b-row>
            </b-col>

            <!-- DROP OFF -->
            <b-col md="6">
              <b-row class="d-flex justify-content-center">
                <b-col md="6">
                  <order-data title="State" :data="order.recipient.state" />
                </b-col>
                <b-col md="6">
                  <order-data title="Country" :data="order.recipient.country" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <div />
        </div>
        <div v-else-if="order.shipment_delivery_type === 'Port to Port'">
          <!-- SENDER & DESTINATION PORT -->
          <b-row class="d-flex justify-content-center">
            <b-col md="6">
              <order-data
                title="Origin Port"
                :data="order.pickup_port ? order.pickup_port.port_name : '-'"
              />
            </b-col>
            <b-col md="6">
              <order-data
                title="Destination Port"
                :data="order.dropoff_port ? order.dropoff_port.port_name : '-'"
              />
            </b-col>
          </b-row>

          <!-- COUNTRY -->
          <b-row class="d-flex justify-content-center">
            <!-- ORIGIN -->
            <b-col md="6">
              <b-row class="d-flex justify-content-center">
                <b-col md="12">
                  <order-data
                    title="Country"
                    :data="order.pickup_port ? order.pickup_port.country : '-'"
                  />
                </b-col>
              </b-row>
            </b-col>

            <!-- DROP OFF -->
            <b-col md="6">
              <b-row class="d-flex justify-content-center">
                <b-col md="12">
                  <order-data
                    title="Country"
                    :data="
                      order.dropoff_port ? order.dropoff_port.country : '-'
                    "
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <!-- PRODUCT DETAILS -->
        <div class="d-flex justify-content-center align-items-center mb-2">
          <hr class="flex-grow-1" />
          <span class="mx-2 font-weight-bold"> Product Details </span>
          <hr class="flex-grow-1" />
        </div>

        <div class="overflow-auto">
          <table class="table table-bordered">
            <thead>
              <th>Movement Arrangement</th>
              <th>Commodity</th>
              <th>Packaging</th>
              <th>Box Type</th>
              <th>Size</th>
              <th>Weight</th>
              <th>Quantity</th>
              <th>Insurance</th>
              <th>Invoice</th>
            </thead>
            <tbody>
              <td>{{ order.movement_arrangement }}</td>
              <td>{{ order.commodity }}</td>
              <td>{{ order.packaging ? order.packaging.text : "-" }}</td>
              <td>{{ order.package_type_option }}</td>
              <td>{{ order.size !== " cm" ? order.size : "-" }}</td>
              <td>{{ order.weight + " " + order.weight_unit }}</td>
              <td>{{ order.quantity }}</td>
              <td>
                {{ order.insurance }}
              </td>
              <td>{{ $helpers.formatTotal(order.invoice_number) }}</td>
            </tbody>
          </table>
        </div>

        <!-- QUOTE DOCUMENTS   -->
        <div v-if="!isQR" class="mt-3">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1" />
            <span class="mx-2 font-weight-bold"> Quoted Documents </span>
            <hr class="flex-grow-1" />
          </div>
          <div class="text-center">
            <div
              v-if="order.quote && order.quote.documents.length"
              class="row justify-content-center"
            >
              <div
                v-for="(doc, index) in order.quote.documents"
                :key="index"
                class="rounded border px-3 py-1 mx-1 mb-1"
              >
                <feather-icon style="color: red" icon="FileIcon" size="25" />
                <a :href="doc.path" target="_blank">
                  {{ doc.name }}
                </a>
              </div>
            </div>
            <div v-else class="d-flex justify-content-center">
              <div>
                <a> No Quoted Document </a>
              </div>
            </div>
          </div>
        </div>

        <!--  Customer Documents    -->
        <div v-if="!isQR" class="mt-3">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1" />
            <span class="mx-2 font-weight-bold"> Customer Documents </span>
            <hr class="flex-grow-1" />
          </div>
          <div class="text-center">
            <div
              v-if="order.customer_documents && order.customer_documents.length"
              class="row justify-content-center"
            >
              <div
                v-for="(document, index) in order.customer_documents"
                :key="index"
                class="rounded border px-3 py-1 mx-1 mb-1"
              >
                <feather-icon style="color: red" icon="FileIcon" size="25" />
                <a :href="document.document" target="_blank">
                  {{ document.document_name }}
                </a>
              </div>
            </div>
            <div v-else class="d-flex justify-content-center">
              <div>
                <a> No Customer Document </a>
              </div>
            </div>
          </div>
        </div>

        <!--  Receipts    -->
        <div v-if="!isQR" class="mt-3">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1" />
            <span class="mx-2 font-weight-bold"> Receipts </span>
            <hr class="flex-grow-1" />
          </div>
          <div class="text-center">
            <div class="row justify-content-center">
              <div class="rounded border px-3 py-1 mx-1 mb-1">
                <feather-icon
                  style="color: red"
                  icon="DownloadIcon"
                  size="25"
                />

                <a href="#" @click.prevent="handleDownload('do')">
                  Download Delivery Order
                </a>
              </div>
              <div class="rounded border px-3 py-1 mx-1 mb-1">
                <feather-icon
                  style="color: red"
                  icon="DownloadIcon"
                  size="25"
                />

                <a href="#" @click.prevent="handleDownload('inv')">
                  Download Invoice
                </a>
              </div>
              <div class="rounded border px-3 py-1 mx-1 mb-1">
                <feather-icon
                  style="color: red"
                  icon="DownloadIcon"
                  size="25"
                />

                <a href="#" @click.prevent="handleDownload('cover')">
                  Download Covernote
                </a>
              </div>
            </div>
          </div>
        </div>
      </b-card>

      <!-- BACK AND QUOTE BUTTTON -->
      <div class="d-flex justify-content-between">
        <b-button variant="primary" @click="$router.go(-1)">
          <feather-icon icon="ArrowLeftIcon" size="15" /> Back
        </b-button>
        <b-button variant="secondary" @click.prevent="quoteOrder">
          {{ statusId === 17 ? "Quote Now" : "Quote" }}
        </b-button>
      </div>
    </b-overlay>

    <b-modal
      v-model="isQuoteModalVisible"
      :title="'Quotation for order ID #' + orderId"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <QuotationForm
        :order_id="Number(orderId)"
        :quotation="order.user_quote"
        :read-only="
          statusId === 17 || (statusId === 12 && !order.quote) ? false : true
        "
        :has-insurance="order.insurance === 'Yes' ? true : false"
        @success="handleQuotationSuccess"
      />
    </b-modal>
  </div>
</template>

<script>
import QuotationForm from "@/components/FleetForwarding/QuotationForm.vue";
import OrderData from "@/components/FleetForwarding/OrderData.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    OrderData,
    QuotationForm,
  },
  data() {
    return {
      isLoading: false,
      isQuoteModalVisible: false,
      isQR: false,
      statusId: 0,
      order: {
        sender: {},
        recipient: {},
        dropoff_port: {},
        pickup_port: {},
      },
      orderId: this.$route.params.id,
    };
  },
  created() {
    this.isLoading = true;
    this.fetchOrders(this.$route.params.id);
  },
  methods: {
    async fetchOrders(id) {
      this.$http.get(`get_fleet_order/${id}`).then((response) => {
        if (response) {
          this.order = response.data.data;
          this.statusId = this.order.status.value;
          if (
            this.statusId === 17 ||
            this.statusId === 12 ||
            this.statusId === 14
          ) {
            this.isQR = true;
          }
        }
        this.isLoading = false;
      });
    },
    async handleDownloadFile(type, orderId) {
      const response = await this.$http.get(`/b2b/${type}/id/${orderId}`, {
        responseType: "blob",
      });
      this.download(type, orderId, response);
    },
    async handleDownloadInvoice(type, orderId, paymentId) {
      const response = await this.$http.get(`download-invoice/${paymentId}`, {
        responseType: "blob",
      });
      this.download(type, orderId, response);
    },

    download(type, orderId, response) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", `${type}-${orderId}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    quoteOrder() {
      this.isQuoteModalVisible = true;
    },
    async handleQuotationSuccess(title, message) {
      this.isQuoteModalVisible = false;
      this.$swal({
        icon: "success",
        title,
        text: message,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    async handleDownload(type) {
      let link = "";
      let fileName = "";
      if (type === "inv") {
        link = `/download-invoice/${
          this.order.logpoint_id
            ? this.order.logpoint_id
            : this.order.payment.id
        }/${this.order.payment_method}`;
        fileName = `invoice-${this.order.id}`;
      } else {
        link = `/delivery-confirmed/${
          this.order.logpoint_id
            ? this.order.logpoint_id
            : this.order.payment.id
        }/${this.order.payment_method}/print/${type}`;
        fileName = `${type}-${this.order.id}`;
      }
      const response = await this.$http.get(link, {
        responseType: "blob",
      });
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", `${fileName}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.padding {
  padding-top: 5px;
}
</style>
